/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/


@import 'assets/stylesheets/mixins';
@import 'assets/stylesheets/typography';

$icon-color: #2C64FF;


$primary: #EBBD07;
$secondary: #968336;
$tertiary: #6B6139;
$fourthiary: #413D2D;


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  overflow: hidden;
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  nav {
    background: $fourthiary;
    overflow: hidden;
    padding: 1rem;
    text-align: center;
    color: #fff;

    position: fixed;
    bottom: 0;
    width: 100%;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;

    .nav-links {
      display: inline-flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 0;
      small {
        display: none;
        @media (min-width:768px) {
          display: block;
        }
        font-size: 12px;
        margin-top: 5px;
      }
      .active {
        color: $primary;
      }
    }
    li {
      flex: 1;
      i {
        @include font-size(12px, 21px, 320px, 1200px);
      }
    }
    a {
      color: #fff;
    }
  }
  nav + section {
    align-self: center;
    padding: 1rem;
  }
  .innerApp {
    height: calc(100vh + 50px);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    padding: 25px;
    margin-left: -25px;
    margin-right: -25px;
    @media (min-width:992px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width:1200px) {
      grid-template-columns: repeat(4, 1fr);
    }



    .musicItem {
      flex: 1;
      text-transform: capitalize;
      img {
       object-fit: contain;
        width: 100%;
      }
      .gridTitle {
        @include font-size(12px, 21px, 320px, 1200px);
        color: $primary;
        font-family: 'Protest Strike', sans-serif;
      }
      .gridLink {
        color: $secondary;
        font-family: 'Protest Strike', sans-serif;
        text-decoration: underline;
      }
    }
  }
  //@media (min-width:1025px) {
    flex-direction: column;
  //}
  .globalHeader {
    &[data-path="/"] {
      position: fixed;
      a { color: #fff; }
    }
    width:100%;
    padding: 1rem;
    a { color: #999; }

  }
}



.animatedLoading {

    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;


  .wrapper {
    width: 100px;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    padding: 10px 0;
  }

  ul {
    margin: 24px 0;
    display: inline-block;
  }


  // Floating dots
  .dots li {
    background: $icon-color;
    border-radius: 100%;
    width: 12px;
    height: 12px;
    float: left;
    margin: 0 2px;
    animation: float 1.8s infinite;
    transform-origin: center;
    &:nth-child(2) {
      animation-delay: .2s;
    }
    &:nth-child(3) {
      animation-delay: .4s;
    }
  }

  @keyframes float {
    0%, 100% {
      transform: translateY(4px);
    }
    50% {
      transform: translateY(-4px);
    }
  }


}

// global styles
a { text-decoration: none; }

.standardTemplate {
  padding: 1em 3em;
  margin-bottom: 8em;
}




.homeView {
  background-image: url("assets/images/me_bg2.jpg"), linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5));
  background-blend-mode: hue;
  background-size: cover;
  min-height: 100vh;
  padding: 1em;
  background-position: -8em -5vw;
  @media (min-width:768px) {
    background-position: 0px -11vw;

  }
}



.messageBox {
  background: rgba(0,0,0,0.35);
  padding: 1rem;
  align-self: center;
  display: inline-block;
  flex-direction: column;
  vertical-align: middle;
  position: absolute;
  top: 33%;
  max-width: 63vw;
}


h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
  font-family: 'Protest Strike', sans-serif;
  color: $primary;
}
.secondary-title {color: #fff; }
.secondaryColor {color: $secondary; }
.headlineAltColor { color: $tertiary; }

p, body, a, html {
  font-family: 'Open Sans', sans-serif;
}
.blackColor { color: #333; }

